<template>
  <div>
    <section class="spesh">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-3">
            <!-- cat-img -->
            <img class="" src="@/assets/images/solarHouse.svg" alt="" />
          </div>
          <div class="col-md-6 center-text">
            <h1 class="categoryHeadd f-bold text-capitalize">Sterling Human</h1>
            <p class="mb-0 text-dark para">
              Check out our various renewable energy solutions and energy saving
              equipments with comfortable financing plans.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="products">
      <div class="container">
        <div class="row align-items-stretch justify-content-center">
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs0_7.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  Solar Home System
                  <div class="cap">0.7KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs0_7.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 6X </span>
                    <span>LED Lights 4W</span>
                  </div>
                  <div v-if="shs0_7.without_solar" class="appl down">
                    <div class="">
                      Only available without Solar Panel (Inverter System)
                    </div>
                    <!-- <div class="count">
                      {{ numberFormat(shs0_7  .without_solar) }}
                    </div> -->
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="shs0_7.active"
                    @click="
                      getStarted(
                        'shs0_7  ',
                        shs0_7.with_solar,
                        shs0_7.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs1.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  Solar Home System
                  <div class="cap">1KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs1.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 6X </span>
                    <span>LED Lights 4W</span>
                  </div>
                  <div v-if="shs1.without_solar" class="appl down">
                    <div class="">
                      Only available without Solar Panel (Inverter System)
                    </div>
                    <!-- <div class="count">
                      {{ numberFormat(shs1  .without_solar) }}
                    </div> -->
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="shs1.active"
                    @click="
                      getStarted('shs1  ', shs1.with_solar, shs1.without_solar)
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs2.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/2.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  AC200
                  <div class="cap">2KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs2.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 8X </span>
                    <span>LED lights 4W</span>
                  </div>
                  <!--  v-if="shs2.without_solar"<div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs2  .without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs2.active"
                    @click="
                      getStarted('shs2  ', shs2.with_solar, shs2.without_solar)
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs3.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  AC300
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs3.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!--  v-if="shs3.without_solar"<div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs3  .without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs3.active"
                    @click="
                      getStarted('shs3  ', shs3.with_solar, shs3.without_solar)
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs33.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  Solar Home System
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs33.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!--  v-if="shs33.without_solar"<div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs33.without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs33.active"
                    @click="
                      getStarted('shs33', shs33.with_solar, shs33.without_solar)
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerup.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerup
                  <div class="cap">1KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch" v-if="powerup.with_solar">
                  <div class="prc">{{ numberFormat(powerup.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 6X </span>
                    <span>LED Lights 4W</span>
                  </div>
                  <div v-if="powerup.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerup.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerup.active"
                    @click="
                      getStarted(
                        'powerup',
                        powerup.with_solar,
                        powerup.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerlite.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerlite
                  <div class="cap">
                    1.5 <br />
                    KVA
                  </div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerlite.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 8X </span>
                    <span>LED lights 4W</span>
                  </div>
                  <div v-if="powerlite.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerlite.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerlite.active"
                    @click="
                      getStarted(
                        'powerlite',
                        powerlite.with_solar,
                        powerlite.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerplus.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/2.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerplus
                  <div class="cap">2.5 <br />KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerplus.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fans - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Tabletop Fridge - 50W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 10X </span>
                    <span>LED lights - 8W</span>
                  </div>
                  <div v-if="powerplus.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerplus.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerplus.active"
                    @click="
                      getStarted(
                        'powerplus',
                        powerplus.with_solar,
                        powerplus.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerxtra.active || powerxtra.without_solar"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerxtra
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch" v-if="powerxtra.with_solar">
                  <div class="prc">
                    {{ numberFormat(powerxtra.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div v-if="powerxtra.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerxtra.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerxtra.active || powerxtra.without_solar"
                    @click="
                      getStarted(
                        'powerxtra',
                        powerxtra.with_solar,
                        powerxtra.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerxtraplus.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerxtraplus
                  <div class="cap">
                    3.3 <br />
                    KVA
                  </div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerxtraplus.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div v-if="powerxtraplus.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerxtraplus.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerxtraplus.active"
                    @click="
                      getStarted(
                        'powerxtraplus',
                        powerxtraplus.with_solar,
                        powerxtraplus.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerultra.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerultra
                  <div class="cap">3.5 <br />KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerultra.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!-- <div class="appl">
                  <span class="count"> 8X </span>
                  <span>LED lights 4W</span>
                </div> -->
                  <div v-if="powerultra.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerultra.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerultra.active"
                    @click="
                      getStarted(
                        'powerultra',
                        powerultra.with_solar,
                        powerultra.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerflex.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerflex
                  <div class="cap">5KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerflex.with_solar) }}
                  </div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 4X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Inverter A/C - 1HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 200W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fridge - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Microwave - 500W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div v-if="powerflex.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerflex.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerflex.active"
                    @click="
                      getStarted(
                        'powerflex',
                        powerflex.with_solar,
                        powerflex.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerpro.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/7.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerpro
                  <div class="cap">
                    7.5 <br />
                    KVA
                  </div>
                </div>
              </div>
              <div class="bt">
                <div class="prch" v-if="powerpro.with_solar">
                  <div class="prc">{{ numberFormat(powerpro.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 250W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fridge - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 4X </span>
                    <span>Standing Fan - 80W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Inverter A/C - 1HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TV Sets - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Microwave - 500W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Washing Machine - 1000W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 20X </span>
                    <span>LED Points - 5W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Sets</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TVs - 50"/42"/32" sets</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Pumping Machine - 1.5HP</span>
                  </div>
                  <div v-if="powerpro.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powerpro.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powerpro.active"
                    @click="
                      getStarted(
                        'powerpro',
                        powerpro.with_solar,
                        powerpro.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powermax.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/10KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powermax
                  <div class="cap">10KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch" v-if="powermax.with_solar">
                  <div class="prc">{{ numberFormat(powermax.with_solar) }}</div>
                  <div class="pera">Outright Payment</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 350W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder </span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span
                      >Inverter A/C - 1HP <span class="count">x1</span>, 2HP
                      <span class="count">x2</span></span
                    >
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 200W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Pumping Machine - 1.5HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Washing Machine - 1000W</span>
                  </div>
                  <div v-if="powermax.without_solar" class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(powermax.without_solar) }}
                    </div>
                  </div>
                </div>

                <div class="sbt">
                  <button
                    v-if="powermax.active"
                    @click="
                      getStarted(
                        'powermax',
                        powermax.with_solar,
                        powermax.without_solar
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 text-left">
          <div class="flex">
            <div class="warnCont">
              <img src="@/assets/images/warn.png" class="warn" alt="" />
            </div>
            <p class="terms">
              Prices are updated monthly and may vary in response to current
              market conditions
            </p>
          </div>
          <div class="flex">
            <div class="warnCont">
              <img src="@/assets/images/warn.png" class="warn" alt="" />
            </div>
            <p class="terms">
              The solar home system is insured for one year; however, Sterling
              Humans who would like to insure the system for subsequent years
              are free to do so at their own discretions.
            </p>
          </div>
          <div class="flex">
            <div class="warnCont">
              <img src="@/assets/images/warn.png" class="warn" alt="" />
            </div>
            <p class="terms">Terms and conditions apply.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="blend"></section>
    <section class="modal" v-if="openModal" @click.self="closeModal">
      <div class="inr">
        <div class="clbtn">
          <span class="bt" @click="closeModal">
            <span></span>
            <span></span>
          </span>
        </div>
        <div>
          <h4>{{ product }}</h4>
          <p class="subt mt-0 mb-0 w-100 text-center">
            fill in your data, and our representative will reach out to you
          </p>
        </div>
        <div>
          <form class="row mt-4" @submit.prevent="Submit">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Full Name"
                  v-model="full_name"
                  class="form-control"
                />
                <p class="err">{{ nameErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Phone Number"
                  v-model="phone_number"
                  class="form-control"
                />
                <p class="err">{{ phoneErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Your Sterling Email"
                  v-model="email"
                  class="form-control"
                />
                <p class="err">{{ mailErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <select
                  placeholder="Preferred Choice"
                  v-model="solar"
                  class="form-control"
                >
                  <option selected disabled value="">
                    ---PREFERRED CHOICE---
                  </option>
                  <option :value="true">With Solar Panel</option>
                  <option :value="false">Without Solar Panel</option>
                </select>
                <p class="err">{{ solarErr }}</p>
              </div>
            </div>
            <!-- <div class="col-md-6 col-12">
              <div class="form-group">
                <select
                  placeholder="How did you hear about us?"
                  v-model="media"
                  class="form-control"
                >
                  <option value="" disabled selected>
                    How did you hear about us?
                  </option>
                  <option value="Switch">Switch</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Facebook">Facebook</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Twitter">Twitter</option>
                  <option value="YouTube">YouTube</option>
                  <option value="Nairaland">Nairaland</option>
                </select>
                <p class="err">{{ mediaErr }}</p>
              </div>
            </div> -->
            <div class="col-12">
              <button class="w-100 text-center mt-2 outline-none">
                Submit
              </button>
              <p class="suc" v-if="success">Request Submitted Successfully!</p>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="loading" v-if="loading">Please Wait...</section>
  </div>
</template>

<script>
import prices from "@/assets/json/prices.json";
export default {
  data() {
    return {
      // powerup: {
      //   // 1KVA
      //   with_solar: 1176971.25,
      //   without_solar: 597240.0,
      // },
      // powerlite: {
      //   // 1.5KVA
      //   with_solar: 1823922.19,
      //   without_solar: 1154512.79,
      // },
      // powerplus: {
      //   with_solar: 1998900.0,
      //   without_solar: 1338100.31,
      // },
      // powerxtra: {
      //   // 3KVA
      //   with_solar: 2093420.0,
      //   without_solar: 1406580.0,
      // },
      // powerultra: {
      //   // 3.5KVA
      //   with_solar: 3169950.0,
      //   without_solar: 1868230.0,
      // },
      // powerflex: {
      //   // 5KVA
      //   with_solar: 4488750.0,
      //   without_solar: 2082400.0,
      // },
      // powerpro: {
      //   // 7.5KVA
      //   with_solar: 7661400.0,
      //   without_solar: 3342150.0,
      // },
      // powermax: {
      //   // 10KVA
      //   with_solar: 9063150.0,
      //   without_solar: 4875255.0,
      // },
      ...prices,
      openModal: false,
      product: "",
      price: 0,
      downpayment: 0,
      full_name: "",
      email: "",
      media: "",
      solar: "",
      phone_number: "",
      nameErr: "",
      phoneErr: "",
      mailErr: "",
      mediaErr: "",
      solarErr: "",
      loading: false,
      success: false,
    };
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.product = "";
      this.price = 0;
      this.downpayment = 0;
      this.email = "";
      this.media = "";
      this.phone_number = "";
      this.full_name = "";
      this.nameErr = "";
      this.phoneErr = "";
      this.mailErr = "";
      this.mediaErr = "";
      this.success = false;
    },
    getStarted(product, price, down) {
      if (product) {
        this.product = product;
        this.price = price;
        this.downpayment = down;
        this.openModal = true;
      } else {
        return false;
      }
    },
    numberFormat(number) {
      if (typeof number !== "number") {
        return "";
      }
      var newNum = Math.ceil(number);
      return "₦" + newNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    Submit() {
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      this.nameErr = "";
      this.phoneErr = "";
      this.mailErr = "";
      this.mediaErr = "";
      this.solarErr = "";
      const address = this.email.trim().split("@").pop();
      // console.log(address);
      if (!this.full_name.length) {
        this.nameErr = "Please Enter your name";
        return false;
      } else if (this.full_name.length < 2) {
        this.nameErr = "Please provide a valid name";
      } else if (this.phone_number.length < 11) {
        this.phoneErr = "Please input a valid phone number";
      } else if (!this.email.length && !this.email.match(validRegex)) {
        this.mailErr = "Please input a valid mail";
      } else if (address.toLowerCase() !== "sterling.ng") {
        this.mailErr = "Please input a valid sterling email";
      } else if (this.solar !== true && this.solar !== false) {
        this.solarErr = "Please make a selection";
      } else {
        this.loading = true;
        // return;
        let bod = JSON.stringify({
          full_name: this.full_name,
          phone_number: this.phone_number,
          email: this.email,
          product: this.product,
          price: this.price,
          downpayment: this.downpayment,
          media: this.media,
          solar: this.solar,
          category: "sterling human",
        });
        // console.log(bod);
        // console.log({
        //   full_name: this.full_name,
        //   phone_number: this.phone_number,
        //   email: this.email,
        //   product: this.product,
        // });
        // console.log(bod);
        // return
        var ebod = this.$helpers.encrypt(bod);

        // console.log(ebod);

        bod = JSON.stringify({ data: ebod });

        // console.log(bod);

        // console.log(process.env.VUE_APP_API_URL);
        // console.log(bod);
        fetch(`${process.env.VUE_APP_API_URL}/newsales`, {
          method: "post",
          body: bod,
          headers: {
            "Content-Type": "Application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data.data);
            data = this.$helpers.decrypt(data.data);
            data = JSON.parse(data);
            // console.log(data);

            if (data.status === "success") {
              // console.log(data);
              // document.getElementById("dlink").click();
              this.loading = false;
              this.success = true;
              this.full_name = "";
              this.email = "";
              this.media = "";
              this.phone_number = "";
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped >
.flex {
  display: flex;
  column-gap: 0.5rem;
  align-items: flex-start;
  .warnCont {
    // background: #c4c4c4;
    border-radius: 100%;
    width: 25px;
    img {
      width: 25px;
    }
  }
}
.suc {
  color: #255e13;
  font-weight: 600;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c488;
  backdrop-filter: blur(3px);
  z-index: 999999999;
  font-weight: 600;
}
.err {
  color: red;
  font-size: 0.8rem;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21965388;
  z-index: 9999999;
  backdrop-filter: blur(3px);
  .inr {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    min-width: 300px;
    .clbtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 4px;
      .bt {
        display: inline-block;
        width: 26px;
        height: 26px;
        position: relative;
        cursor: pointer;
        span {
          position: absolute;
          /* border-radius: 2px; */
          top: 13px;
          left: 0;
          height: 2px;
          background-color: #000;
          width: 100%;
          display: block;
          &:nth-child(even) {
            transform: rotate(45deg);
          }
          &:nth-child(odd) {
            transform: rotate(-45deg);
          }
        }
      }
    }
    h4 {
      /* font-size: 47.6654px; */
      color: #255e13;
      font-weight: 700;
      text-align: center;
    }
    .subt {
      width: 80%;
      margin: auto;
    }
    @media (max-width: 450px) {
      max-width: 320px;
      .subt {
        width: 100%;
      }
    }
    form {
      button {
        border: none;
        border-radius: 0.25rem;
        background-color: #255e13;
        color: #fdd826;
        font-weight: 900;
        padding: 10px 0;
        outline: none;
        transition: all 0.3s ease-out;
        &:hover {
          background-color: #fdd826;
          color: #255e13;
        }
      }
    }
  }
}
.w-100 {
  width: 100%;
}
.spesh {
  background: linear-gradient(
    180deg,
    #f4fcf2 3.01%,
    rgba(204, 222, 171, 0.1607843137)
  );
  background-size: cover;
  padding: 0;
}
.cat-img {
  height: 240px;
}
.center-text {
  text-align: center !important;
}
.categoryHeadd {
  font-size: 47.6654px;
  line-height: 52px;
  color: #255e13;
  font-weight: 700;
}
.products {
  background-color: rgba(204, 222, 171, 0.1607843137);
  padding: 10vh 0;
  .product {
    margin-top: 30px;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    img.w-100 {
      width: 100%;
    }
    .tp {
      background-color: #fff;
      position: relative;
      .ovl {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 60%, #cbdfab);
        display: flex;
        align-items: flex-end;
        padding: 8px;
        font-size: 1.4rem;
        color: #219653;
        font-weight: 800;
        .cap {
          position: absolute;
          bottom: 8px;
          right: 8px;
          width: 60px;
          height: 60px;
          color: #fff;
          background-color: #219653;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 800;
          text-align: center;
          line-height: 1;
        }
      }
    }
    .bt {
      background-color: #cbdfab;
      border-top: 2px solid #fff;
      padding: 8px;
      position: relative;
      .prch {
        position: absolute;
        top: -2px;
        right: 0;
        .prc {
          color: #fdd826;
          font-size: 1.2rem;
          text-align: center;
          font-weight: 800;
          background-color: #255e13;
          padding: 4px 8px;
        }
        .pera {
          padding: 0 6px;
          font-size: 0.6rem;
          background-color: #219653;
          color: #fff;
          text-align: center;
        }
      }
      .applg {
        min-height: 22vh;
        h6 {
          font-weight: 600;
          color: #000;
        }
        .down {
          position: relative;
          /* width: min-content; */
          font-weight: 600;
          margin-top: 1rem;
          /* top: -2px; */
          /* right: 0; */
          /* .prc {
            color: #fdd826;
            font-size: 1.2rem;
            text-align: center;
            font-weight: 800;
            background-color: #255e13;
            padding: 4px 8px;
          }
          .pera {
            padding: 0 6px;
            font-size: 0.6rem;
            background-color: #219653;
            color: #fff;
          } */
        }
      }
      .appl {
        display: flex;
        column-gap: 4px;
        color: #000;
        font-size: 0.85rem;

        .count {
          color: #255e13;
        }
      }
      .sbt {
        /* padding: 0 4px; */
        margin-top: 2rem;
        button {
          width: 100%;
          border: none;
          background-color: #255e13;
          color: #fdd826;
          font-weight: 900;
          padding: 6px 0;
          transition: all 0.3s ease-out;
          &:hover {
            background-color: #fdd826;
            color: #255e13;
          }
          &:disabled {
            color: #255e13;
            cursor: not-allowed;
            background: #f4fcf2;
          }
        }
      }
    }
  }
}
.blend {
  height: 5vh;
  background: linear-gradient(
    180deg,
    rgba(204, 222, 171, 0.1607843137),
    rgba(241, 252, 237, 0)
  );
}
.terms {
  font-size: 1.1rem;
  font-weight: 600;
  //new chanfe
}
</style>
